@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/media";

@radius: 50%;
@space: 2px;

.scrollableContainer {
    width: 390px;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: @screen-sm) {
        width: 100%;
        grid-auto-rows: auto;
    }
}

.iconPickerContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 40px;
    width:348px;
    height: 175px;
    gap: 4px;

    @media (max-width: @screen-sm) {
        width: 100%;
        grid-auto-rows: auto;
    }

    button {
        border: none;
        outline: none;
        padding: 0;
    }
}

.iconPickerItem {
    display: grid;
    place-items: center;
    border-radius: 4px;
    background-color: @iconEditorIcon;

    &:hover, &:focus-within {
        cursor: pointer;
        background-color: @iconEditorIconHover;
    }

    &.selected {
        cursor: default;
    }
}

.colourPaletteContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    margin: 1rem 0 1rem;
}

.colourPaletteContainer > button {
    border: none;
    outline: none;
}

.colourPaletteItem {
    aspect-ratio: 1;
    border-radius: @radius;

    &:hover, &:focus-within {
        cursor: pointer;
        box-shadow: 0 0 0 @space @iconEditorColorPaletteInnerBorder, 0 0 0 (@space + 2) @iconEditorColorPaletteHover;
    }

    &.selected {
        cursor: default;
        box-shadow: 0 0 0 @space @iconEditorColorPaletteInnerBorder, 0 0 0 (@space + 2) @iconEditorColorPaletteSelected;
    }
}