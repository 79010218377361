@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  color: @tenantCount;
  font-size: 0.69rem;
  font-weight: @semibold;
  padding: 0 0.25rem;
  white-space: nowrap;
  border-radius: 0.25em;
  background: @secondaryText;
}

.progress {
  visibility: visible;
}

.expected {
  visibility: visible;
}