@import "../../resources/styles/colors.less";
@import "../../resources/styles/variables";

.container {
    display: flex;
    height: 48px;
    width: 100%;
    max-width: 26.5rem; /* iPad friendly */
    min-width: 0;
    align-items: center;
}

.content {
    font: @textRegularDefaultLarge;
    color: @primaryText;
    letter-spacing: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}