.label {
  display: block;
  margin-top: 1rem;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    .numberPicker {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        .digit {
            width: 4rem;
            margin-right: 0.25rem;

            button {
                margin: auto;
            }
        }
        .digit:first-of-type {
            margin-right: 1rem;
        }

        // remove spinners
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance:textfield;
        }
        input {
            text-align: center;
        }
    }
    .unitOfMeasure {
        margin-top: 1rem;
        display: inline-block;
        align-self: center;
    }
}