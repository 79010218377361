@import "../../resources/styles/colors";

// normal
.phase-icon {
  box-sizing: border-box;
  background: @iconLifeCycle;
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  vertical-align: top;
  margin-right: 8/16rem;
  align-items: center;
  justify-content: center;
  color: @whiteConstant;
  border-radius: 2/16rem;
}

.fontAwesomeIcon(@type, @content) {
  text-rendering: auto;
  position: relative;
  font: var(~"--fa-font-@{type}");
  content: @content;
  font-size: 10/16rem;
}

// optional
.phase-icon.optional {
  background: linear-gradient(90deg, @iconLifeCycle 50%, @whiteConstant 0);
  border: solid 1/16rem @secondaryDivider;
}

// blocked
.phase-icon.blocked {
  background: @primaryBackground !important;
  border: solid 1/16rem @primaryText;
}
.phase-icon.blocked::after {
  content: '';
  width: 10/16rem;
  height: 10/16rem;
  background: @dangerConstant;
  position: relative;
  display: inline-block;
}
.phase-icon.blocked::before {
  display:none;
}

// current
.phase-icon.current {
  background: @imgCyan;
  border: none;
}
// right arrow
.phase-icon.current::before {
  .fontAwesomeIcon(solid, "\f061");
  padding-bottom: 0.125rem;
}

// complete
.phase-icon.complete {
  background: @successConstant;
}
// tick icon
.phase-icon.complete::before {
  .fontAwesomeIcon(solid, "\f00c");
}

// deployed
.phase-icon.deployed {
  background: @successConstant;
}
// tick icon
.phase-icon.deployed::before {
  .fontAwesomeIcon(solid, "\f00c");
}

// automatic
.phase-icon.automatic {
  background: @alertConstant;
}
// lightning bolt icon
.phase-icon.automatic::before {
  .fontAwesomeIcon(solid, "\f0e7");
}

// queued
.phase-icon.queued {
  background: @infoConstant;
}
// clock icon
.phase-icon.queued::before {
  .fontAwesomeIcon(solid, "\f017");
}

// warning
.phase-icon.warning {
  background: @successConstant;
}
// exclamation point in triangle
.phase-icon.warning::before {
  .fontAwesomeIcon(solid, "\f071");
}

// skipped
.phase-icon.skipped {
  width: 1rem;
  height: 1rem;
  background: @secondaryBackgroundLight;
  border: dashed 2/16rem @ternaryText;
}

.phase-icon.executing {
  //empty on purpose
}