@import "variables";

html {
    font-size: 100%;
    color: @primaryText;
}

body {
    font-size: 1rem;
    font-family: @fontFamilyBody;
    margin: 0;
    padding: 0;
    background: @primaryBackground;
    line-height: 1.25rem;
    font-variant-ligatures: none;
    overflow: clip;
}

strong, b {
    font-weight: @semibold;
}

// Links
a {
    text-decoration: none;
}

// Lists
ul,
ol {
    list-style-type: none;
    padding: 0;
}
