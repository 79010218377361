@import "../../resources/styles/fonts.less";
@import "../../resources/styles/colors.less";
@import "../../resources/styles/variables";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    width: 100%;
    min-width: 0;
}

.textOverflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.content {
    font: @textRegularDefaultLarge;
    color: @primaryText;
    letter-spacing: normal;
}

.monospacedFont {
    font-family: @fontFamilyCode;
    font-size: @monospace-font-size;
}