@import "../../../../resources/styles/colors.less";
@import "../../../../resources/styles/media";
@import "../../../../resources/styles/variables";

.pagingEllipsis {
    padding: 0 0.25rem; // Required in the event of long-content.
    margin: 0 0.0625rem;
    min-width: 1.75rem; // min-width required in the event of long-content.
    height: 1.75rem;
    text-align: center;
    color: #707070;
}

.pagingButton {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    padding: 0 0.25rem; // Required in the event of long-content.
    margin: 0 0.0625rem;
    min-width: 1.75rem; // min-width required in the event of long-content.
    height: 1.75rem;
    border-radius: 0.125rem;
    background-color: @transparent;
    color: @secondaryButtonText;

    @media (max-width: @screen-sm) {
        // Make the touch area larger for iPhone/touch situations.
        width: 2rem;
        height: 2rem;
    }

    &:hover {
        background-color: @ternaryButtonHover;
        color: @whiteConstant;
        outline: 0;
    }

    &:active {
        background-color: @ternaryButtonHover;
        color: @whiteConstant;
    }

    &:disabled {
        background-color: @transparent;
        color: @disabledButtonText
    }

    .pagingButtonText {
        width: 0.5rem;
        height: 1.25rem;
        font: @textRegularDefaultXSmall;
        text-align: left;
    }
}

.pagingButtonActive {
    background-color: @ternaryButton;
    color: @whiteConstant;
}

.pagingBar {
    display: flex;
    justify-content: right;
    align-items: center;
}

.pagingSelectText {
    margin-left: 1rem;
    margin-right: 1rem;
}
.pagingSelectContainer {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}
