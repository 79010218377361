@import "../../../../../resources/styles/colors";

.icon {
    margin-right: 0.250rem
}

.infoNote {
    color: @infoHeaderText;
}

.wizardStepper {
    padding: 0rem 1rem;
    border-bottom: 1px solid @dividerLight;

    .wizardIconStepCompleted:global(.MuiStepIcon-root.MuiStepIcon-completed) {
        color: @primaryButtonBackground;
    }
}

.wizardContent {
    margin-top: 1rem;
    text-align: left;

    ul {
        padding-left: 2rem;
        margin-bottom: 0;
        list-style-type: disc;
    }
}

.runbookTypeColumn {
    width: 12rem;
    align-items: right;
}

.runbookList {
    margin: 1rem;
    text-align: left;
    max-height: 15rem;
    overflow-y: scroll;
}

ul.migrationReviewList {
    margin-bottom: 1/2rem;
}
