@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.formSectionBorder() {
    border-radius: 0!important;
    border-style: solid;
    border-width: 0.0625rem 0 0.0625rem 0;
    border-color: @dividerLight;
}

.formExpander, .formNonExpander {
    width: 100%;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
    margin: 0 0 -0.0625rem 0; //so we don't get double borders with consecutive expanders
    .formSectionBorder();

    // mark.siedle - Oh God, these are hidden away inside the Card from material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
    }
}

.formExpander {
    > div:first-child {
        > div:first-child {
            cursor: pointer;
        }
    }
}

.cardMedia {
    margin: 0;
    padding: 0;
}

.cardTitleContainer {
    display: flex;
    align-items: center;
    word-wrap: break-word; // mark.siedle - Needed on settings page for long setting keys to wrap properly.
    font-size: 1rem;
    font-weight: @bold;
    color: @primaryText;
    background-color: @secondaryBackground !important;
    padding: 0.25rem 1rem !important;
    @media (max-width: @screen-sm) {
        display:block;
    }
    line-height: 2.25rem;
}

.overflowMenuActions {
    margin-left: auto;
    align-items: center;
  }