@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.empty {
    font-size: 1rem;
    margin: 0 1rem;
    line-height: 1.5rem;
}

.cardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    color: @primaryText;
    padding: 0 1rem;
    cursor: pointer; // Whole row is clickable, show cursor.
    .workerPoolIcon {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
        width:1.5rem;
        height:auto;
    }
    .workerPoolName {
        color: @mainText;
        font-weight: @semibold;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
        white-space: nowrap;
        font-size: 1rem;
    }
    .workerPoolMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .workerPoolSummaryCounts {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto; // This pulls it right.
        margin-right: 5rem; // Margin to avoid the absolute position caret.
        @media (max-width: @screen-sm) {
            display: none; // Too many table/padding issues at small res.
        }
        .summaryCount {
            margin: 0.25rem 0;
            padding-right: 0.4rem;
            display: flex;
            align-items: center;
            line-height: 1rem !important;
            font-size: 0.8125rem !important; // Done on purpose to match design, this has to match for both the action button and the close button.
            text-transform: uppercase; // To match ternary action so this toggles seamlessly.
        }
    }
    .workerPoolOverflowActions {
        position: absolute;
        z-index: 1;
        top: 0px;
        bottom: 0px;
        right: 3rem; // Leave enough room for the down-arrow on the right.
        margin: auto;
        width: 3rem;
        height: 2.25rem;
    }
}

.machineIcon {
    width: 2.438rem;
    height: 2.563rem; // Always set height or the screen gets janky as images load.
}

.healthStatusIconContainer {
    margin-right:0.25rem;
    vertical-align: middle;
    border: 0.0625rem solid @logoBackground;
    background: @logoBackground;
    border-radius: 1.875rem;
}

.healthStatusCardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 0;
    margin-left: 1.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 0.75rem;
    }
    .healthStatusIconContainer {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .healthStatusName {
        color: @primaryText;
        font-weight: @semibold;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .healthStatusMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
}
