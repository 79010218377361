@import "../../resources/styles/colors.less";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

.popoverMenu a {
    color: @whiteConstant;
}

.pagingControlButton {
    cursor: pointer;
    border: 0;
    padding: 0 0.25rem; // Required in the event of long-content.
    margin: 0 0.0625rem;
    min-width: 1.75rem; // min-width required in the event of long-content.
    height: 1.75rem;
    border-radius: 0.125rem;
    background-color: @pagingButtonBackground;
    color: @secondaryText;

    @media (max-width: @screen-sm) {
        // Make the touch area larger for iPhone/touch situations.
        width: 2rem;
        height: 2rem;
    }

    &:hover,
    &:focus {
        background-color: @pagingButtonBackgroundHover;
        color: @whiteConstant;
        outline: 0;
    }

    &:active {
        background-color: @pagingButtonBackgroundActive;
        color: @secondaryText;
    }

    .pagingControlButtonText {
        width: 0.5rem;
        height: 1.25rem;
        font: @textRegularDefaultXSmall;
        text-align: left;
    }
}

.pagingControlButtonActive {
    background-color: @pagingButtonBackgroundActive;
    color: @whiteConstant;
}

.pagingControlButtonInactive {
    background-color: @pagingButtonBackground;
    color: @primaryText;
}

.buttonWrapper {
    display: inline-block;
}
