@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/variables";

.container {
  margin: 0.5rem 0;
}

.ok {
  line-height: 1.6rem;
  margin-left: 0;
  margin-bottom: 0.0625rem;
  background-size: 1.5rem auto;
  b {
    display: flex;
    flex-direction: row;
  }
}

.error {
  line-height: 1.6rem;
  margin-left: 0;
  margin-bottom: 0.0625rem;
  background-size: 1.5rem auto;
  b {
    display: flex;
    flex-direction: row;
  }
}

dfn {
  font-style: normal;
  color: @primaryText;
  margin-left: 0.5rem;
}

.buildInformationTitle {
  font-size: 1.0rem;
}

.buildInformationSummary {
  color: @primaryText;
  font-size: 0.8125rem;
  @media (max-width: @screen-sm) {
      padding: 0;
  }
}

.buildInformationSummaryLabel {
  width: 12rem;
  text-align: left;
  font-weight: @semibold;
  @media (max-width: @screen-sm) {
      text-align: initial;
  }
}

.buildInformationSummary table {
  background-color: transparent;
}
.buildInformationSummary tr:hover {
  background-color: transparent !important;
}
.buildInformationSummary tr {
  border: 0 !important;
}
.buildInformationSummary td {
  padding: 0.125rem 0 !important;
}