@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";
@import "../../../../resources/styles/variables";

.machinePolicySummary {
    color: @primaryText;
    font-size: 0.8125rem;
    @media (max-width: @screen-sm) {
        padding: 0;
        display: none; // Too many table/padding issues at small res.
    }
}
.machinePolicySummaryLabel {
    text-align: left;
    font-weight: @semibold;
    @media (max-width: @screen-sm) {
        text-align: initial;
    }
}
.machinePolicySummary table {
    background-color: transparent;
}
.machinePolicySummary tr:hover {
    background-color: transparent !important;
}
.machinePolicySummary tr {
    border: 0 !important;
}
.machinePolicySummary td {
    padding: 0.125rem 0 !important;
}