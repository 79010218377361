@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.dynamicEnvironmentOverviewProjectStateCell {
    display: flex;
    gap: 0.35rem;
    align-items: center;
}

.dynamicEnvironmentOverviewProjectOverflowMenuCell {
    display: flex;
    justify-content: flex-end !important;
}

.dynamicEnvironmentOverviewProjectSortBySelect {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 24rem;
}

.dynamicEnvironmentOverviewProjectSortByLabel {
    min-width: 6rem;
    text-align: right;
    margin-bottom: 0.7rem;
    font-weight: @semibold;
    color: @secondaryText;
}

.dynamicEnvironmentOverviewProjectFooter {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
}

.dynamicEnvironmentOverviewProjectTableRow {
    height: 78px;
}

.dynamicEnvironmentOverviewProjectTableRowColumnData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dynamicEnvironmentOverviewProjectTableRowColumn {
    vertical-align: middle;
}

.sortBySelection {
    color: @secondaryText;
}

.dynamicEnvironmentOverviewAssociatedProjectsHeading {
    margin-left: 1rem;
    font-size: 1.1rem;
    font-weight: @normal;
}

.dynamicEnvironmentOverviewProjectTaskColumnData {
    margin-left: -0.5rem;
}

.projectLink {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.dynamicEnvironmentOverviewSummaryContainer {
    border-top: 0.0625rem solid @secondaryDivider;
    border-bottom: 0.0625rem solid @secondaryDivider;
    padding: 0 1rem 1rem 1rem;
}

.dynamicEnvironmentOverviewSummaryContainerLight {
    background: @primaryBackground;
}

.dynamicEnvironmentOverviewSummaryContainerDark {
    background: @secondaryBackgroundLight;
}

.dynamicEnvironmentOverviewSummaryHeading {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: @normal;
}

.dynamicEnvironmentOverviewSummaryCardContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
}

.dynamicEnvironmentOverviewSummaryCard {
    padding: 1rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: @radiusMd;
    background: @cardBackground;
    min-width: 165px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dynamicEnvironmentOverviewSummaryCardLight {
    border-color: @secondaryDivider;
}

.dynamicEnvironmentOverviewSummaryCardDark {
    border-color: @chipBackground;
}

.dynamicEnvironmentOverviewSummaryCardHeader {
    font-size: 1rem;
    font-weight: @semibold;
}

.dynamicEnvironmentOverviewSummaryCardHeaderLight {
    color: @primaryText;
}

.dynamicEnvironmentOverviewSummaryCardHeaderDark {
    color: @secondaryText;
}

.dynamicEnvironmentOverviewSummaryCardContent {
    margin: 0;
    font-size: 1rem;
    color: @primaryText;
    font-weight: @normal;
}

.dynamicEnvironmentNoRunbookTask {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.dynamicEnvironmentNoRunbookTaskDetails {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.dynamicEnvironmentNoRunbookTaskTitle {
    font-weight: @semibold;
    font-size: 0.9375rem;
}

.dynamicEnvironmentNoRunbookTaskSubtitle {
    font-size: 0.6875rem;
    color: @ternaryText;
}