@import "../../resources/styles/variables";
@import "../../resources/styles/colors.less";

.markdownNote {
    font: @textRegularDefaultXSmall;
    text-align: left;
    margin-bottom: 0.5rem;
    color:@ternaryText;

    div {
        font-size: 0.75rem;
    }
}

.markdownDescriptionContainer {
    div {
        padding-top: 0.125rem;

         p {
            margin: 0px;
        }
    }
}

.testingResult {
    display: flex;
    span:nth-child(2) {
        margin-left: 0.5rem
    }
}