@import "../../../../../resources/styles/colors.less";

.indicatorContainer {
    padding: 0.5rem 0;

    // Keep centered (for use in expanders).
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.itemContainer {
    color: @tooltipText;
    margin: 0;
}