@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";

.taskQueueRow {
    display: flex;
    flex-direction: row;
    min-width: 180px;
    padding: 0.5rem;
    margin: 0.25rem 0;
    justify-content: left;
    align-items: flex-start;

    .taskDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 0.5rem;
    }

    .taskPosition {
        display:flex;
        align-items:center;
        color: @ternaryText;
        font-size: 0.6875rem;
        width: 2rem;
    }

    .taskCurrent {
        display:flex;
    }
}

.taskQueueFilter {
    margin-right: 1rem!important;
    font-size: 0.8125rem !important;
    font-weight: @semibold!important;

    &.selected {
        color: @primaryText!important;
    }

    .filterContainer {
        display: inline-flex;
        align-items: center;
    }

    .closeButton {
        display: inline-flex;
        padding: 0!important;

        svg {
            margin-right: 0!important;
            fill: @iconNeutral!important;
        }
    }
}