@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.logoEditor {
  margin: 1rem 0;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  align-items: center;
  position: relative; // We absolutely position the logo input in here, so this is needed.

  .resetButton {
    margin-bottom: 10px;
  }
}

.logoNote, .logoNoteBold {
  color: @secondaryText;
  font-size: 14px;
  font-weight: @normal;
  line-height: 20px;
}

.logoNoteBold {
  font-weight: @semibold;
}
