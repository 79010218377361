@import "../../resources/styles/variables";
@import "../../resources/styles/colors";

@help-sidebar-width: 340px;

:root {
    --help-sidebar-width: 340px;
}

.root {
    display: flex;
}

.mainContent {
    display: flex;
    flex-direction: column;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-right: calc(var(--help-sidebar-width) * -1);
    flex-grow: 1;
    // The Raw Task page overflows incorrectly without setting min-width to 0 here.
    // 'white-space: pre' has unexpected overflow results in a flexbox and min-width is the culprit
    min-width: 0;
}

.mainContentShift {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-right: 0;
}

.wrapper {
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @dividerLight;
    padding: 16px 24px;
}

.heading {
    margin: 0;
    line-height: 24px;
    font-weight: @bold;
}

.drawer {
    width: var(--help-sidebar-width);
    flex-shrink: 0;
}

.drawerHidden {
    transition: visibility 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    visibility: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    container-type: size;
}

.footer {
    padding: 0.75rem 0;
    margin: 0 1rem;
    border-top: 1px solid @dividerLight;
    h4 {
        margin: 0.25rem 0;
    }
    a, span {
        font-size: 0.875rem;
    }
}

#drawerwrapper {
    h4 {
        font-size: 0.875rem;
        font-weight: @semibold;
        margin: 1rem 0 0.15rem 0;
    }
}

// We can avoid using !important to override mui styles by increasing specificity with .class.class
.drawerPaper.drawerPaper {
    background-color: @paper1;
    width: var(--help-sidebar-width);
    top: auto;
    height: auto;
    border-left: none;
    z-index: 9; // Needs to be less than the GettingStartedFooter.
    box-shadow: var(--shadowLayoutPrimary);
}

.content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 16px 24px;
}

