@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.dashboardOnboardingLayout {
    display: flex;
    flex-direction: row;

    max-width: 80%;
    margin-top: 3rem;
    @media (max-width: @screen-md) {
        margin-top: 0.25rem;
        flex-direction: column-reverse;
    }
}

.dashboardOnboardingText {
    line-height: 24px;
    max-width: 30rem;
    color: @ternaryText;
    padding: 10rem 3rem 10rem 0;
    @media (max-width: @screen-md) {
        max-width: 400px;
        padding: 0.5rem 0;
    }
}

.checkboxGrid {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 5px;
    row-gap: 10px;

    @media (min-width: @screen-sm) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.lifecycleSummary {
    margin-top: 2rem;
}

.lifecycleList {
    list-style-type: decimal;
    list-style-position: inside;
    font-weight: @bold;
}

.lifecycleListItem {
    float: left;
    padding-right: 1rem;
}

.successfulDeploymentDialog {
    display: flex;
    flex-direction: row;

    max-width: 1000px;
    max-height: 100%;
    background: transparent;
    box-shadow: @shadowLarge;
}

.successfulDeploymentDialogContent {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 450px;
    max-height: 100%;

    background: @paper1;
    padding: 1rem;
    border-radius: 4px 0 0 4px;

    @media (max-width: @screen-sm) {
        border-radius: 4px;
    }
}

.successfulDeploymentDialogImage {
    height: 100%;
    border-radius: 0 4px 4px 0;
    @media (max-width: @screen-sm) {
        display: none;
    }
}

.successfulDeploymentDialogTitle {
    flex-grow: 1;
    font-size: 20px;
    font-weight: @semibold;
    padding-left: 1rem;
    padding-right: 1rem;
}

.successfulDeploymentDialogText {
    font-size: 16px;
    line-height: 24px;
}

.externalLinkIcon {
    margin-right: 1rem;
    font-size: 0.8125rem;
    color: @linkText;
}

.blankPageOuterContainer {
    margin: 2rem auto;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    @media (max-width: @screen-md) {
        margin: auto;
        padding: 0;
        width: 90%;
        flex-direction: column-reverse;
        min-width: 0;
    }
}

.blankPageContentContainer {
    display: flex;
    flex-direction: column;
    color: @ternaryText;
    font-size: 0.875rem;
    width: 50%;
    min-width: 300px;
    max-width: 320px;
    @media (max-width: @screen-md) {
        margin: auto;
        width: 100%;
        max-width: 400px;
        min-width: 0;
        padding: 1rem 0 3rem 0;
    }
}

.header {
    color: @primaryText;
    font-weight: @semibold;
    line-height: 26px;
}

.description {
    color: @ternaryText;
    margin-bottom: 1rem;
}

.externalLinks {
    color: @ternaryText;
    display: flex;
    align-items: center;
    > a,
    > div {
        margin: 0 2rem 1rem 0;
    }
}

.noFrameFlex {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.linkList {
    font-size: 0.85rem;
}

.icon {
    margin-right: 0.15em;
}

.dialogTitle {
    padding-bottom: 0.5rem;

    div:first-child {
        font-size: 1.25rem;
        font-weight: @semibold;
        line-height: 1.75rem;
    }
}

.dialogTitleFullScreen {
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 0;
    background-color: unset;

    div:first-child {
        font-size: 1.5rem;
        font-weight: @semibold;
        line-height: 2rem;
    }
}

.environmentsContainerFullScreen {
    max-width: 35.5rem;
}

.addEnvironmentContainer {
    margin-top: 0.75rem;
    float: right;
}

.addEnvironmentContainerFullScreen {
    float: left;
}

.savingError {
    list-style-type: disc;
}

.lifecycle {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.customDialogContentFullScreen {
    padding: 0;
}

.buttonContainer {
    display: flex;
    gap: 0.75rem;
    justify-content: end;
    padding: 0.75rem 1rem;
}

.buttonsContainerFullScreen {
    padding: 0;
}
