@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.note {
    font: @textRegularDefaultXSmall;
    text-align: left;
    margin-bottom: 0.5rem;
    color: @ternaryText;
    a {
        color: @mainText;
    }
    .checkboxNote {
        display: none;
    }
}

.header {
    font-size: 0.75rem;
    font-weight: @semibold;
    color: @ternaryText;
}
