@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

@commonMargin: 0.5rem;
@notesStackedWidth: 90rem;

.stepList {
  display: flex;
  flex-direction: column;
  > :last-child {
    > .listItemLine, > .listItem .listItemLine {
      display: none;
    }
    .stepContainer {
      border-bottom: unset;
    }
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 1rem; // This affects the background on hover, play carefully.
  margin: 0;
  position: relative;
  overflow: visible;
  z-index: 0;
  height: auto;
  background-color: transparent;
  //border-top:  0.0625rem solid transparent;
  //border-bottom:  0.0625rem solid transparent;
  color: @primaryText;
  margin-top: -0.125rem;
  margin-bottom: -0.0625rem;
  .listItemLine {
    position: absolute;
    top: @commonMargin;
    left: 2.6rem;
    height: 100%;
    border-left: 0.0625rem solid @ternaryText;
  }
  .parallelContainer {
    padding: 0.25rem;
    border-radius: 0.625rem;
    position: absolute;
    top: -0.25rem; // This has to consider regular steps, step with long content and child steps.
    left: 2.1rem;  // Be VERY careful here and re-test on all browsers and zoom scalings if you change this.
    display: flex;
    justify-content: center;
    z-index: 100;
    width: 1rem;
    height: 1rem;
    background: @disabledButtonBorder;
    align-items: center;
  }
  .parallelIcon {
    width: 0.75rem;
    height: 0.375rem;
    border-top: 0.125rem solid @iconNavy;
    border-bottom: 0.125rem solid @iconNavy;
    .content {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .stepContainer {
    border-bottom: 0.0625rem solid @dividerLight;
    border-top: 0.0625rem solid transparent;
    margin-left: @commonMargin;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0 0.75rem 0;
  }
  .stepContent {
    display: flex;
    flex-direction: row;
    // On bigger screens the step summaries and notes are shown side-by-side, on smaller screens they are stacked
    @media (max-width: @notesStackedWidth) {
      flex-direction: column;
    }
  }
  .summary {
    flex: 1 1 0;
    display: block;
    align-items: center; // Needed when we add chips to summaries so the text lines up correctly.
    width: 100%;
    font-size: 0.875rem;
    color: @primaryText;
    font-weight: @normal;
  }
  .contextualActions {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .childThatSitsAboveVerticalLine() {
    position: relative;
    z-index: 10;
    background-color: @stepLogoBackground;
  }
  .stepLogo {
    width: 3.2rem;
    height: 3.2rem;
    z-index: 1;
    flex-shrink: 0;
    border: 0.0625rem solid @dividerLight;
    border-radius: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 0.0625rem;
    margin-top: 0.5rem;
    .childThatSitsAboveVerticalLine();
    svg {
      padding-left: 0.0625rem;
      width: auto;
      height: 60%;
    }
    img {
      width: 3.2rem;
      border-radius: 2.2rem;
      height: auto;
    }
  }
  .stepName {
    width: 100%; //IE11
    color: @linkText;
    font-weight: @semibold;
    font-size: 1rem;
    flex-direction: column;
    justify-content: flex-start;
  }
  .stepNumber {
    font-weight: @semibold;
    margin: 0 0.25rem 0 0;
    font-size: 1rem;
  }
  .stepNotes {
    flex: 1 1 0;
    .notes {
      background-color: @infoBackground;
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0.1rem 1rem;
      margin-right: 1rem;

      @media (max-width: @notesStackedWidth) {
        margin-top: 1rem;
      }
    }

  }
  &:hover {
    background-color: @hover !important;
    margin-top: -0.125rem;
    .stepContainer {
      .stepContent {
        .stepNumber, .stepName {
          color: @linkTextHover;
        }
      }
    }
  }
}

.isDisabled > .stepContainer > .stepContent > .stepName > .summary > div > span > span div {
  opacity: 0.7;
  color: @chipText; //to make sure the chip text is visible
}

.isDisabled * {
  color: @disabledButtonText;
  .stepNumber {
    padding-top: 0.25rem;
    color: @disabledButtonText;
  }
}
.isDisabled.listItem:hover >.stepContainer > .stepContent > .stepNumber {
  color: @disabledButtonText; //to make sure there is no hover state changes expect for the background
}

.group {
  > .listItem {
    align-items: stretch; // Needed for full height child logos.
    .stepName,
    .stepNumber {
      font-size: 0.875rem;
    }
    .stepLogo {
      border-radius: 0;
      margin: 0;
      height: 100%;
      border-left: 0.0625rem solid @dividerLight;
      border-right: 0.0625rem solid @dividerLight;
      border-top: 0.0625rem solid @dividerLight;
      border-bottom: 0.0625rem solid transparent;
      :first-child {
        background-color: transparent
      }

    }
    .stepContainer {
      line-height: 1.25rem;
    }
  }
  > a:first-child {
    .stepName,
    .stepNumber {
      font-size: 1rem;
    }
    .stepLogo {
      border-bottom: 0 solid transparent;
      background-color: @parentStepBackground;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      justify-content: center;
      margin-top: @commonMargin;
      svg {
        fill: @whiteConstant;
        width: auto;
        height: 100%;
        padding-left: 0;
      }
    }
    &:hover {
      svg {
        fill: @primaryText;
      }
      .stepLogo {
        border-top: 0.0625rem solid @dividerLight;
      }
    }
  }
  > a:last-child {
    .stepLogo {
      border-top: 0.0625rem solid @dividerLight;
      border-bottom: 0.0625rem solid @dividerLight;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      margin-bottom: @commonMargin;
      flex: 1; //added flex instead of height so the div grows according to the row height
    }
    &:hover {
      .stepLogo {
        border-bottom: 0.0625rem solid @dividerLight;
      }
    }
  }
  a:first-child.isDisabled {
    > .stepWrapper > div > .stepLogo {
        background-color: @disabledBackground;
        .stepIcon {
          opacity: 1;
        }
    }
    > .stepContainer > .stepContent > .stepName > .summary {
        color: @disabledButtonText;
        > span > span div {
          opacity: 0.7;
          color: @chipText;
        }
    }
  }
.stepWrapper {
  display: flex;
  flex-direction: column;
  div:nth-child(2) {
    flex: 1;
  }
}

.stepIcon {
  svg {
    border-radius: 50%;
  }
}
}

.summaryContainer {
  display: flex;
}