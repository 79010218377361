@import "../../../../../resources/styles/variables";

.container {
    display: flex;
    align-items: flex-end;
}

.value {
    font-weight: @semibold;
}

.positiveTrend {
    color: #00B065;
}

.negativeTrend {
    color: #FF4848;
}