@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";

.container {
    min-height: 62.5rem; // UX: Create empty vertical space to avoid scroll issues when switching between categories.
    display: flex;
    flex-flow: column;
    margin-top: -1.875rem;
}

.pageTitle {
    font-size: 1.5rem;
    font-weight: @bold;
    line-height: 2rem;
}

.filterSearchBoxContainer {
    width: 320px; // No larger than 320 for mobile
    @media (min-width: @screen-md) {
        width: 460px;
    }
}

.sectionDivider {
    padding: 8/16rem 18/16rem;
    margin-right: 1rem;
    font-size: 17/16rem;
    border-radius: 0.25rem;
}

.stepSelectionContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    @media (min-width: @screen-md) {
        flex-direction: row;
    }
}

.categoriesContainer {
    @media (min-width: @screen-md) {
        border-right: 1px solid @dividerLight;
    }
}

.stepsContainer {
    width: 100%;
}

.searchHeader {
    overflow: hidden;
    color: @primaryText;
    text-overflow: ellipsis;
    font-size: 1.25rem;
    font-weight: @bold;
    line-height: 1.7rem;
    padding: 1rem 1rem 0 1rem;
}
