@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.container {
    display: flex;
    flex-direction: row;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
    .sidebar {
        width: 20rem;
        border-right: 0.0625rem solid @dividerLight;
        @media (max-width: @screen-sm) {
            width: 100%;
            border-right: unset;
            border-bottom: 0.0625rem solid @dividerLight;
        }
    }
    .content {
        width: 100%;
    }
}

.headerBody {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 1rem;
}

.headerBodyFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}